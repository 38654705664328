import { t } from "@lingui/macro";
import { BreadcrumbItem, Menu, Page, PageHeader } from "@ingka-livlig/frontend-lib";
import { AppSettings } from "./AppSettings";
import styles from "./MetricsPage.module.css";
import React, { PropsWithChildren } from "react";
import { useLingui } from "@lingui/react";

const MetricsPage: React.FC<{ breadcrumbItems?: BreadcrumbItem[] } & PropsWithChildren> = ({
    children,
    breadcrumbItems,
}) => {
    useLingui(); // Needed to make the t`` macro work

    return (
        <Page
            title={t`Metrics`}
            headerElement={
                <PageHeader
                    headerText={
                        <a href={"/locations/global"} className={styles.header_link}>
                            Metrics
                        </a>
                    }
                    actionElement={<AppSettings />}
                >
                    <Menu currentApp="metrics" />
                </PageHeader>
            }
            breadcrumbItems={breadcrumbItems}
        >
            {children}
        </Page>
    );
};

export default MetricsPage;
