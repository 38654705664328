import React from "react";
import {
    currentCrumb,
    extractSelectedLanguage,
    linkCrumb,
    LoadingIndicator,
    NavigationList,
    Segment,
} from "@ingka-livlig/frontend-lib";

import MetricsPage from "./MetricsPage";
import useApiRequest from "./useApiRequest";
import styles from "./MetricsRole.module.css";
import { useParams } from "react-router-dom";
import { LocationType } from "./api";
import { Trans } from "@lingui/macro";

export const MetricsRole: React.FC = () => {
    const { locationType, locationId } = useParams<{
        locationType: LocationType;
        locationId: string;
    }>();

    const locale = extractSelectedLanguage();

    const metricsRoleQuery =
        locationType &&
        (locationType === "Global" || locationType === "GlobalOnly"
            ? useApiRequest("globalMetricsQuery", locationType)
            : useApiRequest("metricsQuery", locationType, locationId ?? ""));

    const locationName =
        metricsRoleQuery?.tag === "success" && locationType === "Country"
            ? metricsRoleQuery.value.countryName
            : metricsRoleQuery?.tag === "success" && locationType === "SellingUnit"
              ? metricsRoleQuery.value.sellingUnitScope?.name
              : "Global";

    const crumbs = () => {
        if (metricsRoleQuery?.tag == "success") {
            switch (locationType) {
                case "Global":
                    return [linkCrumb(`Global`, `/locations/global`), currentCrumb(`Role`)];
                case "GlobalOnly":
                    return [linkCrumb(`GlobalOnly`, `/locations/GlobalOnly`), currentCrumb(`Role`)];
                case "Country":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(`${metricsRoleQuery.value.countryName}`, `/locations/Country/${locationId}/`),
                        currentCrumb(`Role`),
                    ];
                case "CountryOnly":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(`${metricsRoleQuery.value.countryName}`, `/locations/CountryOnly/${locationId}/`),
                        currentCrumb(`Role`),
                    ];
                case "SellingUnit":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(
                            `${metricsRoleQuery.value.sellingUnitScope?.countryScope.name}`,
                            `/locations/Country/${metricsRoleQuery.value.sellingUnitScope?.countryScope.countryCode}/`,
                        ),
                        linkCrumb(
                            `${metricsRoleQuery.value.sellingUnitScope?.name}`,
                            `/locations/SellingUnit/${metricsRoleQuery.value.sellingUnitScope?.suCode}/`,
                        ),
                        currentCrumb(`Role`),
                    ];
            }
        }
    };

    function renderContent() {
        switch (metricsRoleQuery?.tag) {
            case "loading":
                return <LoadingIndicator />;
            case "error":
                return (
                    <p className={styles.metric_message}>
                        <Trans>Failed to fetch the Metrics Data</Trans>
                    </p>
                );
            case "success":
                const role =
                    locationType === "Country" || locationType == "SellingUnit" || locationType == "CountryOnly"
                        ? metricsRoleQuery.value.rolePageViews
                        : metricsRoleQuery.value.roleActivePageViews;

                return (
                    <>
                        <div className={styles.metricsInfo}>
                            <div className={styles.mainTitle}>
                                {locationName}
                                <div>
                                    <Trans>Roles</Trans>
                                </div>
                                <h5>
                                    <Trans>Page views last 35 days</Trans>
                                </h5>
                            </div>
                        </div>

                        <div>
                            {role.map(({ role, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <span className={styles.countryView}>{role}</span>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                        </div>
                    </>
                );
        }
    }

    return (
        <MetricsPage breadcrumbItems={crumbs()}>
            <Segment variant="primary" padded>
                {renderContent()}
            </Segment>
        </MetricsPage>
    );
};
