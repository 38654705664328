import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { convert, LocalDate } from "@js-joda/core";
import { extractSelectedLanguage } from "@ingka-livlig/frontend-lib";

interface PageViewGraphProps {
    data: { date: number; pageView: number }[];
}

export const PageViewGraph: React.FC<PageViewGraphProps> = ({ data }) => {
    const locale = extractSelectedLanguage();

    function formatXAxis(dateValue: number) {
        {
            const t = LocalDate.parse(String(dateValue));
            return convert(t).toDate().toLocaleDateString(locale, {
                day: "numeric",
                month: "short",
            });
        }
    }

    function formatYAxis(pageViewValue: number) {
        return Intl.NumberFormat(locale, { notation: "compact" }).format(pageViewValue);
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{ top: 20, left: -52, right: 28, bottom: 10 }}>
                <CartesianGrid stroke="#dddddd" />
                <XAxis
                    dataKey="date"
                    stroke="#484848"
                    tick={{ fontSize: 9 }}
                    tickMargin={4}
                    tickCount={4}
                    tickFormatter={formatXAxis}
                />
                <YAxis
                    type="number"
                    width={100}
                    stroke="#484848"
                    tickMargin={4}
                    tick={{ fontSize: 9 }}
                    tickFormatter={formatYAxis}
                />

                <Line
                    name="Page views"
                    type="monotone"
                    dataKey="pageView"
                    stroke="#888888"
                    strokeWidth={1}
                    isAnimationActive={false}
                    dot={false}
                    strokeDasharray="3 2"
                    connectNulls={false}
                />
                <Legend
                    iconType="plainline"
                    iconSize={12}
                    height={24}
                    verticalAlign="top"
                    align="right"
                    wrapperStyle={{ fontSize: 12 }}
                />
                <Tooltip />
            </LineChart>
        </ResponsiveContainer>
    );
};
