import React from "react";
import {
    currentCrumb,
    extractSelectedLanguage,
    linkCrumb,
    LoadingIndicator,
    NavigationList,
    Segment,
} from "@ingka-livlig/frontend-lib";

import MetricsPage from "./MetricsPage";
import useApiRequest from "./useApiRequest";
import styles from "./MetricsHfbPage.module.css";
import { useParams } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { LocationType } from "./api";

export const MetricsHfbPage: React.FC = () => {
    const { locationType, locationId } = useParams<{
        locationType: LocationType;
        locationId: string;
    }>();

    const metricsHfbPageQuery =
        locationType &&
        (locationType === "Global" || locationType === "GlobalOnly"
            ? useApiRequest("globalMetricsQuery", locationType)
            : useApiRequest("metricsQuery", locationType, locationId ?? ""));

    const locationName =
        metricsHfbPageQuery?.tag == "success" && (locationType === "Country" || locationType === "CountryOnly")
            ? metricsHfbPageQuery.value.countryName
            : metricsHfbPageQuery?.tag == "success" && locationType === "SellingUnit"
              ? metricsHfbPageQuery.value.sellingUnitScope?.name
              : "Global";

    const crumbs = () => {
        if (metricsHfbPageQuery?.tag == "success") {
            switch (locationType) {
                case "Global":
                    return [linkCrumb(`Global`, `/locations/global`), currentCrumb(`Most Viewed HFB`)];
                case "GlobalOnly":
                    return [linkCrumb(`GlobalOnly`, `/locations/GlobalOnly`), currentCrumb(`Most Viewed HFB`)];
                case "Country":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(`${metricsHfbPageQuery.value.countryName}`, `/locations/Country/${locationId}/`),
                        currentCrumb(`Most Viewed HFB`),
                    ];
                case "CountryOnly":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(`${metricsHfbPageQuery.value.countryName}`, `/locations/CountryOnly/${locationId}/`),
                        currentCrumb(`Most Viewed HFB`),
                    ];
                case "SellingUnit":
                    return [
                        linkCrumb(`Global`, `/locations/global`),
                        linkCrumb(
                            `${metricsHfbPageQuery.value.sellingUnitScope?.countryScope.name}`,
                            `/locations/Country/${metricsHfbPageQuery.value.sellingUnitScope?.countryScope.countryCode}/`,
                        ),
                        linkCrumb(
                            `${metricsHfbPageQuery.value.sellingUnitScope?.name}`,
                            `/locations/SellingUnit/${metricsHfbPageQuery.value.sellingUnitScope?.suCode}/`,
                        ),
                        currentCrumb(`Most Viewed HFB`),
                    ];
            }
        }
    };

    const locale = extractSelectedLanguage();

    function renderContent() {
        switch (metricsHfbPageQuery?.tag) {
            case "loading":
                return <LoadingIndicator />;
            case "error":
                return (
                    <p className={styles.metric_message}>
                        <Trans>Failed to fetch the Metrics Data</Trans>
                    </p>
                );
            case "success":
                const hfbName = metricsHfbPageQuery.value.hfbScopes;

                return (
                    <React.Fragment>
                        <div className={styles.metricsInfo}>
                            <div className={styles.mainTitle}>
                                {locationName}
                                <div>
                                    <Trans>Most Viewed HFB</Trans>
                                </div>
                                <h5>
                                    <Trans>Page views last 35 days</Trans>
                                </h5>
                            </div>
                        </div>

                        <div>
                            {metricsHfbPageQuery.value.hfbPageViews.map(({ rangeId, pageView }, index) => (
                                <NavigationList key={index}>
                                    <div className={styles.entry}>
                                        <div className={styles.indexSmall}>{index + 1}</div>
                                        <div className={styles.rangeView}>
                                            {hfbName.find((el) => el.id === rangeId)?.name}
                                            <div>HFB {rangeId}</div>
                                        </div>
                                        <span className={styles.pageView}>{pageView.toLocaleString(locale)}</span>
                                    </div>
                                </NavigationList>
                            ))}
                        </div>
                    </React.Fragment>
                );
        }
    }

    return (
        <MetricsPage breadcrumbItems={crumbs()}>
            <Segment variant="primary" padded>
                {renderContent()}
            </Segment>
        </MetricsPage>
    );
};
