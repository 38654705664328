import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Global } from "./Global";
import { AppBase, TrackedRoute, TranslatePage, useEvents, UserNavigationEvent } from "@ingka-livlig/frontend-lib";
import { localeActivate } from "./i18n";
import { withStore } from "react-context-hook";
import NotFound from "./NotFound";
import { useAPI } from "./api";
import React from "react";
import { MetricsCountry } from "./MetricsCountry";
import { MetricsSellingUnit } from "./MetricsSellingUnit";
import { MetricsRole } from "./MetricsRole";
import { MetricsMostViewedPage } from "./MetricsMostViewedPage";
import { MetricsHfbPage } from "./MetricsHfbPage";
import * as englishMessages from "./locales/en/messages.mjs";

const Translate = () => {
    const api = useAPI();
    return (
        <TranslatePage
            recordTranslation={api.recordTranslation}
            fetchEnglishTransations={() => englishMessages.messages}
        />
    );
};

const App = () => {
    const events = useEvents<UserNavigationEvent>(useAPI());
    const suOrCountryPath = "/locations/:locationType/:locationId";

    const router = createBrowserRouter([
        {
            element: <AppBase localeActivate={localeActivate} />,
            children: [
                {
                    path: "/",
                    element: (
                        <TrackedRoute routeName="Root" events={events}>
                            <Global />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/translate",
                    element: (
                        <TrackedRoute routeName="Translate" events={events}>
                            <Translate />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/locations/SellingUnit/:locationId",
                    element: (
                        <TrackedRoute routeName="MetricsSellingUnit" events={events}>
                            <MetricsSellingUnit />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/locations/:locationType",
                    element: (
                        <TrackedRoute routeName="Global" events={events}>
                            <Global />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/locations/:locationType/role",
                    element: (
                        <TrackedRoute routeName="MetricsRole" events={events}>
                            <MetricsRole />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/locations/:locationType/HFB",
                    element: (
                        <TrackedRoute routeName="MetricsHfbPage" events={events}>
                            <MetricsHfbPage />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/locations/:locationType/feature",
                    element: (
                        <TrackedRoute routeName="MetricsMostViewedPage" events={events}>
                            <MetricsMostViewedPage />
                        </TrackedRoute>
                    ),
                },
                {
                    path: `${suOrCountryPath}`,
                    element: (
                        <TrackedRoute routeName="MetricsCountry" events={events}>
                            <MetricsCountry />
                        </TrackedRoute>
                    ),
                },
                {
                    path: `${suOrCountryPath}/HFB`,
                    element: (
                        <TrackedRoute routeName="MetricsHfbPage" events={events}>
                            <MetricsHfbPage />
                        </TrackedRoute>
                    ),
                },
                {
                    path: `${suOrCountryPath}/role`,
                    element: (
                        <TrackedRoute routeName="MetricsRole" events={events}>
                            <MetricsRole />
                        </TrackedRoute>
                    ),
                },
                {
                    path: `${suOrCountryPath}/feature`,
                    element: (
                        <TrackedRoute routeName="MetricsMostViewedPage" events={events}>
                            <MetricsMostViewedPage />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "*",
                    element: (
                        <TrackedRoute routeName="NotFound" events={events}>
                            <NotFound />
                        </TrackedRoute>
                    ),
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};

export default withStore(App, {});
