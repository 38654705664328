import { Settings } from "@ingka-livlig/frontend-lib";
import React from "react";
import { localeActivate } from "./i18n";

/**
 * Made into a separate component to allow us to easily add more settings entries.
 * @constructor
 */
export const AppSettings: React.FC = () => {
    return <Settings key={"settings"} localeActivate={localeActivate} />;
};
