import React from "react";

import "@ingka-livlig/frontend-lib/dist/style.css";
import App from "./App";

import { setupErrorReporting } from "@ingka-livlig/frontend-lib";
import { setupBuildInfo } from "./buildInfo";
import { createRoot } from "react-dom/client";

//Setup build info to be either info for the app or the frontend lib, whatever is more recent.
setupBuildInfo();
setupErrorReporting("metrics");

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
