import { useStore } from "react-context-hook";
import {
    AppUser,
    fetchWithUserCredentials,
    getBackendURL,
    publishEvents,
    UserEvent,
    UserEventPublisher,
} from "@ingka-livlig/frontend-lib";
import "@js-joda/timezone";
import { useMemo } from "react";

const hostname = getBackendURL();

export type LocationType = "SellingUnit" | "Country" | "GlobalOnly" | "CountryOnly" | "Global";

export interface MetricsQuery {
    pageViews: Array<{ countryName: string; locationId: string; pageView: number }>; //Global Entity attributes
    pageViewsByDate: Array<{ date: number; pageView: number }>; //Global Entity Attributes
    roleActivePageViews: Array<{ role: string; pageView: number }>; //Global Entity Attributes
    countryTotalPageViewsByDate: Array<{ date: number; pageView: number }>;
    countryPageViews: Array<{ locationId: string; storeName: string; pageView: number; locationType: string }>;
    rolePageViews: Array<{ role: string; pageView: number }>;
    featurePageViews: Array<{ feature: string; pageView: number }>;
    hfbPageViews: Array<{ rangeId: string; pageView: number }>;
    hfbScopes: Array<{ id: string; name: string }>;
    countryName: string;
    sellingUnitScope?: { suCode: string; name: string; countryScope: { countryCode: string; name: string } };
    sellingUnitPageViewsByDate: Array<{ date: number; pageView: number }>;
}

export interface API extends UserEventPublisher {
    globalMetricsQuery(locationType: LocationType): Promise<MetricsQuery>;

    metricsQuery(locationType: LocationType, locationId: string): Promise<MetricsQuery>;

    recordTranslation(payload: {
        author: string;
        language: string;
        original: string;
        existingTranslation: string;
        translated: string;
        comment?: string;
    }): Promise<void>;
}

function api(user: AppUser): API {
    async function fetchWithCredentials(input: RequestInfo, init?: RequestInit): Promise<Response> {
        return fetchWithUserCredentials(user, input, init);
    }

    async function fetchJsonWithCredentials(url: string) {
        const result = await fetchWithCredentials(url);
        if (result.status === 204) {
            return null;
        } else {
            return await result.json();
        }
    }

    function buildUri(locationType: LocationType, locationId: string) {
        if (locationType === "CountryOnly") {
            return `${hostname}/api/locations/Country/${locationId}/Only`;
        } else {
            return `${hostname}/api/locations/${locationType}/${locationId}`;
        }
    }

    function buildGlobalUri(locationType: LocationType) {
        if (locationType === "GlobalOnly") {
            return `${hostname}/api/locations/global/Only`;
        } else {
            return `${hostname}/api/locations/global`;
        }
    }

    return {
        globalMetricsQuery: async (locationType: LocationType) => {
            return await fetchJsonWithCredentials(buildGlobalUri(locationType));
        },
        metricsQuery: async (locationType: LocationType, locationId: string) => {
            return await fetchJsonWithCredentials(`${buildUri(locationType, locationId)}`);
        },

        recordTranslation: async (payload: {
            author: string;
            language: string;
            original: string;
            existingTranslation: string;
            translated: string;
            comment?: string;
        }) => {
            await fetchWithCredentials(`${hostname}/api/translations`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
                method: "POST",
            });
        },

        publishEvents: async (events: UserEvent<any>[]) => {
            await publishEvents(events, user, `${hostname}/api/events`);
        },
    };
}

export function useAPI() {
    const [user] = useStore<AppUser>("user");
    return useMemo(() => api(user), [user]);
}
