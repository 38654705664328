import { PromisedType, RequestSingleState, RequestState, useApiCall } from "@ingka-livlig/frontend-lib";
import { API, useAPI } from "./api";

/**
 * React hook for stateful HTTP requests of a single REST entity.
 *
 * @param method - name of method in the {@link API} interface
 * @param args - arguments for the method named by `method`
 */
function useApiRequest<Method extends keyof API, Body extends PromisedType<ReturnType<API[Method]>>>(
    method: Method,
    ...args: Parameters<API[Method]>
): RequestSingleState<Body>;

/**
 * React hook for stateful HTTP requests of a REST list of entities.
 *
 * @param method - name of method in the {@link API} interface
 * @param args - arguments for the method named by `method`
 */
function useApiRequest<Element, Method extends keyof API>(
    method: Method,
    ...args: Parameters<API[Method]>
): RequestState<Array<Element>>;

//
// (Implementation of the above signatures.)
//
function useApiRequest<Method extends keyof API, Response extends PromisedType<ReturnType<API[Method]>>>(
    method: Method,
    ...args: Parameters<API[Method]>
): RequestState<Response> | RequestSingleState<Response> {
    const api = useAPI();
    const f = api[method] as (...args: any) => Promise<Response>;
    return useApiCall(f, ...args);
}

export default useApiRequest;

export { unlessSuccessful } from "@ingka-livlig/frontend-lib";
